'use client';

import React, { useEffect, useState, useRef } from 'react';
interface GridPatternProps {
  cellSizePx: number;
  className?: string;
  colorConfig?: {
    hue: number; // 0-360
    saturation: number; // 0-100
    lightness: number; // 0-100
    alpha: number; // 0-1
    // Array of first two probabilities (third is calculated)
    distribution: [number, number]; // [70, 15] means 70%, 15%, and remaining 15%
    backgroundColor?: string;
  };
  regenerateCount?: number;
}
export const defaultColorConfig = {
  hue: 0,
  saturation: 0,
  lightness: 89,
  alpha: 1,
  distribution: [70, 15] as [number, number],
  backgroundColor: '#ffffff'
};
export function GridPattern({
  cellSizePx,
  className = '',
  colorConfig = defaultColorConfig,
  regenerateCount = 0
}: GridPatternProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({
    columns: 0,
    rows: 0
  });
  const [gridKey, setGridKey] = useState(0); // state for forcing re-render

  useEffect(() => {
    const updateDimensions = () => {
      if (typeof window !== 'undefined' && containerRef.current) {
        const columns = Math.ceil(window.innerWidth / cellSizePx);
        const rows = Math.ceil(containerRef.current.offsetHeight / cellSizePx);
        setDimensions({
          columns,
          rows
        });
      }
    };
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [cellSizePx]);
  useEffect(() => {
    if (regenerateCount > 0) {
      let count = 0;
      const interval = setInterval(() => {
        count++;
        setGridKey(prev => prev + 1);
        if (count >= 2) {
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [regenerateCount]);
  const generateHslaColor = (opacity: number) => {
    const {
      hue,
      saturation,
      lightness,
      alpha
    } = colorConfig;
    return `hsla(${hue}, ${saturation}%, ${lightness}%, ${opacity * alpha})`;
  };
  const generateGrid = () => {
    const grid = [];
    const [firstProb, secondProb] = colorConfig.distribution;
    const thirdProb = 100 - (firstProb + secondProb);

    // Validate probabilities
    if (firstProb + secondProb > 100) {
      console.warn('Distribution probabilities exceed 100%, using default values');
      return [];
    }
    for (let i = 0; i < dimensions.rows; i++) {
      const row = [];
      for (let j = 0; j < dimensions.columns; j++) {
        const random = Math.random() * 100;
        let color;
        if (random < firstProb) {
          color = generateHslaColor(0.2); // Lightest
        } else if (random < firstProb + secondProb) {
          color = generateHslaColor(0.4); // Medium
        } else {
          color = generateHslaColor(0.6); // Darkest
        }
        row.push(color);
      }
      grid.push(row);
    }
    return grid;
  };
  const grid = generateGrid();
  const borderColor = generateHslaColor(0.5);
  return <div ref={containerRef} className={`h-full w-full overflow-hidden ${className}`} data-sentry-component="GridPattern" data-sentry-source-file="grid-pattern.tsx">
      <div className="grid h-full w-full" style={{
      display: 'grid',
      gridTemplateColumns: `repeat(${dimensions.columns}, ${cellSizePx}px)`,
      gridTemplateRows: `repeat(${dimensions.rows}, ${cellSizePx}px)`,
      gap: '1px',
      backgroundColor: colorConfig.backgroundColor,
      padding: '1px',
      border: `1px solid ${borderColor}`
    }}>
        {grid.map((row, i) => row.map((cell, j) => <div key={`${i}-${j}`} style={{
        backgroundColor: cell,
        boxShadow: `inset 0 0 0 1px ${borderColor}`
      }} />))}
      </div>
    </div>;
}